@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-gray-700;
}

li.slide  {
  margin: auto !important;
}
